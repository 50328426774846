// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HWMvidRceNJ3Bkgj1X3O2A\\=\\= {\n  display: flex;\n  align-items: center;\n  font-size: 11px;\n  justify-content: center;\n  margin-bottom: 10px;\n}\n\n.xL0DcFYJ39jr-1PPPOnXVQ\\=\\= {\n  display: flex;\n  justify-content: center;\n}\n\n.nlS9buWK0Qy1gHksIkgBEA\\=\\= {\n  width: 37px;\n  text-align: center;\n\n  /* if for some reason the contents can't fit \n   * don't break into 2 lines */\n  white-space: nowrap;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://../../../webapp/javascript/components/FlameGraph/FlameGraphComponent/DiffLegend.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;;EAElB;+BAC6B;EAC7B,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".flamegraph-legend {\n  display: flex;\n  align-items: center;\n  font-size: 11px;\n  justify-content: center;\n  margin-bottom: 10px;\n}\n\n.flamegraph-legend-list {\n  display: flex;\n  justify-content: center;\n}\n\n.flamegraph-legend-item {\n  width: 37px;\n  text-align: center;\n\n  /* if for some reason the contents can't fit \n   * don't break into 2 lines */\n  white-space: nowrap;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flamegraph-legend": "HWMvidRceNJ3Bkgj1X3O2A==",
	"flamegraph-legend-list": "xL0DcFYJ39jr-1PPPOnXVQ==",
	"flamegraph-legend-item": "nlS9buWK0Qy1gHksIkgBEA=="
};
export default ___CSS_LOADER_EXPORT___;
