// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IaxRGnKZ36N\\+bnegdva3lA\\=\\= {\n  overflow: auto;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://SimplePanel.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;AACd","sourcesContent":[".panel {\n  overflow: auto;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "IaxRGnKZ36N+bnegdva3lA=="
};
export default ___CSS_LOADER_EXPORT___;
