// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tbTNyLpy329SqsvXNKhhcg\\=\\= {\n  position: absolute;\n  pointer-events: none;\n  background: #ffffff40;\n  mix-blend-mode: overlay;\n}\n", "",{"version":3,"sources":["webpack://../../../webapp/javascript/components/FlameGraph/FlameGraphComponent/Highlight.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":[".highlight {\n  position: absolute;\n  pointer-events: none;\n  background: #ffffff40;\n  mix-blend-mode: overlay;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlight": "tbTNyLpy329SqsvXNKhhcg=="
};
export default ___CSS_LOADER_EXPORT___;
