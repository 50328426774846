// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iwqDD3ckHGYqDwQaNNae3w\\=\\= {\n  position: absolute;\n  pointer-events: none;\n  /* make it a bit lighter so that it's easier to distinguish when both highlights are on */\n  background: #ffffff8c;\n  mix-blend-mode: overlay;\n}\n", "",{"version":3,"sources":["webpack://../../../webapp/javascript/components/FlameGraph/FlameGraphComponent/ContextMenuHighlight.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,yFAAyF;EACzF,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":[".highlightContextMenu {\n  position: absolute;\n  pointer-events: none;\n  /* make it a bit lighter so that it's easier to distinguish when both highlights are on */\n  background: #ffffff8c;\n  mix-blend-mode: overlay;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlightContextMenu": "iwqDD3ckHGYqDwQaNNae3w=="
};
export default ___CSS_LOADER_EXPORT___;
