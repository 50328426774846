// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1r9\\+etoKlEqfl0oYjqws2Q\\=\\= {\n  cursor: pointer;\n}\n\n.kwWCa\\+ygPeVnxAX1y7RP7w\\=\\= {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  position: relative;\n  padding: 60px 0;\n}\n", "",{"version":3,"sources":["webpack://../../../webapp/javascript/components/FlameGraph/FlameGraphComponent/canvas.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".hover {\n  cursor: pointer;\n}\n\n.error {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  position: relative;\n  padding: 60px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hover": "_1r9+etoKlEqfl0oYjqws2Q==",
	"error": "kwWCa+ygPeVnxAX1y7RP7w=="
};
export default ___CSS_LOADER_EXPORT___;
