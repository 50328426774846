// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SN86uxkUpTgr1P6ZoZAO4A\\=\\= {\n  width: 100%;\n}\n\n/* We take a gamble here and assume only 2 items will exist */\n.SN86uxkUpTgr1P6ZoZAO4A\\=\\= > *:first-child {\n  margin-right: 8px;\n}\n.SN86uxkUpTgr1P6ZoZAO4A\\=\\= > *:nth-child(2) {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://../../../webapp/javascript/components/FlameGraph/FlamegraphRenderer.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA,6DAA6D;AAC7D;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".flamegraphContainer {\n  width: 100%;\n}\n\n/* We take a gamble here and assume only 2 items will exist */\n.flamegraphContainer > *:first-child {\n  margin-right: 8px;\n}\n.flamegraphContainer > *:nth-child(2) {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flamegraphContainer": "SN86uxkUpTgr1P6ZoZAO4A=="
};
export default ___CSS_LOADER_EXPORT___;
