// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jVaF8a7YwOieKPtrqPUMGw\\=\\= {\n  display: flex;\n  margin: 10px 0;\n  height: 40px;\n  align-items: center;\n}\n\n/* Give same amount of space between all children */\n.jVaF8a7YwOieKPtrqPUMGw\\=\\= > *:not(:first-child):not(:last-child) {\n  margin: 0 8px;\n}\n\n.HVRnnFTudn4sQ32m5Lzgpg\\=\\= {\n  flex-grow: 1;\n}\n\n.H7h87OiO3mmyeMmsgOnRKg\\=\\= {\n  background: rgba(255, 255, 255, 0.8);\n  color: #333;\n  transition: background-color ease-out 0.1s;\n  margin-right: 8px;\n}\n.H7h87OiO3mmyeMmsgOnRKg\\=\\=:focus {\n  background: rgba(255, 255, 255, 1);\n}\n\n.rIfXJanWCW1kPKcGTfpM9Q\\=\\= {\n  width: 100px;\n}\n\n.c0cS2w16VptS\\+qYKUPcdtw\\=\\= {\n  margin: 0 8px;\n}\n", "",{"version":3,"sources":["webpack://../../../webapp/javascript/components/ProfilerHeader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,YAAY;EACZ,mBAAmB;AACrB;;AAEA,mDAAmD;AACnD;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,WAAW;EACX,0CAA0C;EAC1C,iBAAiB;AACnB;AACA;EACE,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":[".navbar {\n  display: flex;\n  margin: 10px 0;\n  height: 40px;\n  align-items: center;\n}\n\n/* Give same amount of space between all children */\n.navbar > *:not(:first-child):not(:last-child) {\n  margin: 0 8px;\n}\n\n.space-filler {\n  flex-grow: 1;\n}\n\n.search {\n  background: rgba(255, 255, 255, 0.8);\n  color: #333;\n  transition: background-color ease-out 0.1s;\n  margin-right: 8px;\n}\n.search:focus {\n  background: rgba(255, 255, 255, 1);\n}\n\n.search-small {\n  width: 100px;\n}\n\n.fit-mode-select {\n  margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "jVaF8a7YwOieKPtrqPUMGw==",
	"space-filler": "HVRnnFTudn4sQ32m5Lzgpg==",
	"search": "H7h87OiO3mmyeMmsgOnRKg==",
	"search-small": "rIfXJanWCW1kPKcGTfpM9Q==",
	"fit-mode-select": "c0cS2w16VptS+qYKUPcdtw=="
};
export default ___CSS_LOADER_EXPORT___;
