// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QEK05GVdEN5neSrk6YKSmA\\=\\= {\n  padding-bottom: 5px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.QEK05GVdEN5neSrk6YKSmA\\=\\= > div:first-child {\n  width: 100%;\n}\n\n.tTzDe2VPDr8Ha2cI9oQFyg\\=\\= {\n  display: flex;\n  justify-content: center;\n}\n\n.OhX12jtxSHeGX9\\+Fp982wA\\=\\= {\n  width: 100%;\n  display: block;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://../../../webapp/javascript/components/FlameGraph/FlameGraphComponent/Header.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".flamegraph-header {\n  padding-bottom: 5px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.flamegraph-header > div:first-child {\n  width: 100%;\n}\n\n.row {\n  display: flex;\n  justify-content: center;\n}\n\n.flamegraph-title {\n  width: 100%;\n  display: block;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flamegraph-header": "QEK05GVdEN5neSrk6YKSmA==",
	"row": "tTzDe2VPDr8Ha2cI9oQFyg==",
	"flamegraph-title": "OhX12jtxSHeGX9+Fp982wA=="
};
export default ___CSS_LOADER_EXPORT___;
